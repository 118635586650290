<template>
    <div class="flex flex-v flex-align-center flex-pack-center frame" style="position:relative;top:60px;height:calc(100% - 60px);" ref="frame" :class="{mobile:isMobile}">
        <div class="login-title">
            <div>重置密码</div>
        </div>
        <div style="">
            <div class="login flex">
                <div class="login-left-bg">
                    <img src="../../assets/images/login/denglu_bg.jpg" alt="">
                </div>
                <div class="login-right flex-1">
                    <div class="login-tab">
                        <div>重置密码</div>
                    </div>
                    <div style="margin-top: 30px;">
                        <forget  @success="onLoginSuccess" @register="onRegister"></forget>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
    import _ from 'lodash';
    import forget from '@/components/login/forget.vue'
    export default {
        components: { forget },
        data() {
            return {
                activeName: 'account',
                isMobile:false
            }
        },
        created() {
            
        },
        mounted(){
            this.isMobile = this.$refs['frame'].offsetWidth<600;
            window.onresize = () => {
                this.isMobile = this.$refs['frame'].offsetWidth<600;
            }
        },
        methods: {
            onLoginSuccess(){
                this.$emit("success");
                this.$router.push('/center');
            },
            onRegister(){
                if(this.isMobile){
                    this.$router.push('/mobile/register');
                }else{
                    this.$router.push('/register');
                }
            }
        },
        destroyed(){
            window.onresize = null;
        }
    }

</script>
<style scoped>
    .login {
        width: 800px;
        /* height: 560px; */
        align-self: center;
        background:rgba(255,255,255,1);
        box-shadow:0px 4px 8px 0px rgba(200,207,228,1);
    }
    .login-title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        color:#292929;
        font-size:18px;
        font-weight:bold;
        box-shadow:0px 2px 2px 0px rgba(228,231,237,1);
        position: absolute;
        top: 0px;
        left: 0;
    }
    .login-title>div {
        width: 1200px;
        margin: 0 auto;
        padding: 0 30px;
    }
    .login-left-bg {
        width: 400px;
        /* height: 100%; */
    }
    .login-left-bg>img {
        width: 100%;
        height: 100%;
    }
    .login-right {
        height: 519px;
        padding: 40px 60px;
    }
    .login-tab>div{
        text-align: center;
        cursor: pointer;
        font-size:18px;
        font-weight: bold;
        color: #292929;
        /* padding: 7px 0; */
    }
    .login-tab>div.active{
        color: #5074EE;
        border-bottom: 2px solid #5074EE;
    }
    .frame.mobile .login-title{
      display: none;
    }
    .frame.mobile .login{
        width: auto;
        box-shadow: none;
    }
    .frame.mobile .login-left-bg{
      display: none;
    }
</style>