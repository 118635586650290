<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-form">
            <el-form-item style="margin-bottom:20px;" prop="mobile">
                <el-input v-model="ruleForm.mobile" :maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="captcha">
                <el-input v-model="ruleForm.captcha" placeholder="请输入图形验证码" class="reg-create-captcha-ipt">
                    <div slot="append" v-bind:style="{background:captchaBg}" class="captcha-img"
                        @click="refreshCaptcha">
                    </div>
                </el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="code">
                <el-input placeholder="请输入短信验证码" v-model="ruleForm.code">
                    <template slot="append">
                        <div v-if="invsec<=0" @click="getMobileCode" style="cursor: pointer;">获取验证码</div>
                        <div v-if="invsec>0">{{invsec}}秒后重试</div>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="new_passwd">
                <div>
                    <el-input placeholder="请输入密码 (8～20位,区分大小写)" 
                        v-model="ruleForm.new_passwd" :maxlength="20" 
                        type="text" onfocus="this.type='password'">
                    </el-input>
                </div>
                <div v-if="ruleForm.new_passwd && ruleForm.new_passwd.length>7" class="plan" style="margin-top:14px;">
                    <div class='plan-progress flex'>
                        <div :style="planNum && planNum>=1 ? 'background:red;': ''" class="flex-1"></div>
                        <div :style="planNum && planNum>=2 ? 'background:orange;': ''" class="flex-1"></div>
                        <div :style="planNum && planNum==4 ? 'background:#00D1B2;': ''" class="flex-1"></div>
                    </div>
                    <div class="plan-text flex flex-justify-around">
                        <span style="color:red;">弱</span>
                        <span style="color:orange;">中</span>
                        <span style="color:#00D1B2;">强</span>
                    </div>
                </div>
            </el-form-item>
            <el-form-item style="margin-bottom:25px;" prop="new_cpasswd">
                <el-input placeholder="请再次输入新密码" v-model="ruleForm.new_cpasswd" 
                    type="text" onfocus="this.type='password'">
                </el-input>
            </el-form-item>
        </el-form>
        <div>
            <el-button 
                @click="submitForm('ruleForm')" 
                @keyup.enter="submitForm('ruleForm')" 
                style="width: 100%;" type="primary" round
                >重置密码并登录</el-button>
        </div>
    </div>
</template>
<script>
    import { getCaptCha, sendGetSmsCode } from '@/service/common';
    import { phoneReg } from "@/utils";
    import { findPasswordReset,createMobileAccountAgo } from '@/service/account';
    export default {
        props: ['phone'],
        data() {
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入手机号'));
                } else {
                    const reg = phoneReg;
                    if (reg.test(value)) {
                        createMobileAccountAgo({
                            mobile: this.ruleForm.mobile,
                        }).then((rst) => {
                            if (rst.reg==0) {
                                return callback(new Error('该手机号尚未注册'));
                            } else {
                                callback();
                            }
                        }).catch(err => {
                            console.log(err);
                            callback();
                        });
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            let checkPass = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入密码'));
                } else {
                    if (this.ruleForm.new_passwd) {
                        //正则表达式验证符合要求的
                        if(value.length < 8 || value.length > 20) {
                            return callback(new Error('请输入密码 (8～20位, 区分大小写)'));
                        } else {
                            callback();
                        }
                    } else {
                        return callback(new Error('请与上面填写密码保持一致'));
                    }
                }
            };
            let checkCpass = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请再次输入密码'));
                } else {
                    if (this.ruleForm.new_cpasswd == this.ruleForm.new_passwd) {
                        callback();
                    } else {
                        return callback(new Error('与上面密码不匹配'));
                    }
                }
            };
            return {
                ruleForm: {
                    mobile: '',
                    code:'',
                    new_passwd:'',
                    captcha: '',
                    captcha_token: '',
                },
                rules: {
                    mobile: [{validator: checkPhone, trigger: 'blur'}],
                    captcha: [
                        { required: true, message: '请输入图形验证码', trigger: 'blur' }
                    ],
                    code: [
                        { required: true, message: '请输入短信验证码', trigger: 'blur' }
                    ],
                    new_passwd: [
                        { validator: checkPass, trigger: 'blur' }
                    ],
                    new_cpasswd: [
                        { validator: checkCpass, trigger: 'blur' }
                    ],
                },
                captchaBg: null,
                captchaToken: null,
                invsec: 0,
                timer: null,
                planNum:0,
            }
        },
        watch:{
            'ruleForm.new_passwd':{
                handler(newValue, oldValue) {
                    console.log(newValue);
                    if (newValue) {
                        this.checkPlan(newValue);
                    }
                },
                deep: true
            }
        },
        created() {
            if (this.phone) {
                this.ruleForm.mobile = this.phone;
            }
            // enter快捷键登录
            let _self = this;
            document.onkeydown = function(e){
                let key = window.event.keyCode;
                if(key == 13 || key == 100){
                    _self.submitForm('ruleForm');
                }
            }
        },
        mounted() {
            this.refreshCaptcha();
        },
        methods: {
            checkPlan(value) {
                // console.log(value);
                //正则表达式验证符合要求的
                if(value.length < 8 || value.length > 20) return this.planNum;
                if((/\d/.test(value)) || (/[a-z]/.test(value)) || (/[A-Z]/.test(value)) || (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=1; //数字
                if((/\d/.test(value)) && (/[a-z]/.test(value)) || (/\d/.test(value)) && (/[A-Z]/.test(value)) || (/\d/.test(value)) && (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=2; //小写
                if(this.planNum==2 && (/[A-Z]/.test(value)) || this.planNum==2 && (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=3; //大写  
                if(this.planNum==3 && (/^.*[^a-zA-Z0-9]+.*/.test(value)) || this.planNum==3 && (/[A-Z]/.test(value))) this.planNum=4; //特殊字符
                console.log(this.planNum);
                //逻辑处理
                switch(this.planNum) {
                    case 1:
                        return 1;
                    case 2:
                        return 2;
                    case 3:
                        return 3;
                    case 4:
                        return value.length < 10 ? 3 : 4;
                }
                console.log(this.planNum);
            },
            goto(path) {
                this.$router.push(path);
            },
            refreshCaptcha() {
                getCaptCha().then(rsp => {
                    this.captchaBg = `url('data:image/svg+xml,${encodeURIComponent(rsp.svg)}') no-repeat center`;
                    this.captchaToken = rsp.token;
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            getMobileCode() {
                sendGetSmsCode(this.ruleForm.mobile).then(() => {
                    this.invsec = 60;
                    this.timer = setInterval(() => {
                        if (this.invsec == 0) {
                            clearInterval(this.timer);
                            return;
                        }
                        this.invsec--;
                    }, 1000);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                })
            },
            submitForm(formName) {
                console.log(this.ruleForm);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.ruleForm.captcha_token = this.captchaToken;
                        findPasswordReset(this.ruleForm).then(()=>{
                            this.$emit("success");
                        }).catch(err=>{
                            this.refreshCaptcha();// 刷新二维码
                            this.$message.error(err.message);
                            return false;
                        });
                    } else {
                        this.refreshCaptcha();// 刷新二维码
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        }
    }

</script>
<style>
    .reg-create-captcha-ipt .el-input-group__append {
        padding: 0 !important;
        overflow: hidden;
    }
</style>
<style scoped>
    .captcha-img {
        height: 38px;
        background-size: 100% 100% !important;
        width: 130px;
        cursor: pointer;
    }

    /* 密码强度 */
    .plan-progress>div {
		height: 10px;
        background: #eee;
        margin-bottom: 8px;
	}
	.plan-text span{
        font-size: 14px;
		line-height: 16px;
	}
</style>