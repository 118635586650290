export const deDuplicationArr = (arr = [], attr = "id") => {
  let hash = {};
  return arr.reduce((cur, next) => {
    hash[next[attr]] ? "" : (hash[next[attr]] = true && cur.push(next));
    return cur;
  }, []);
}

export const phoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;

// Date的‘toJSON’方法返回格林威治时间的JSON格式字符串，实际是使用‘toISOString’方法的结果。字符串形如‘2018-08-09T10:20:54.396Z’，转化为北京时间需要额外增加八个时区，我们需要取字符串前19位，然后把‘T’替换为空格，即是我们需要的时间格式。
function transformDate(time = +new Date()) {
  let date = new Date(time + 8 * 3600 * 1000);
  return date.toJSON().substr(0, 19).replace("T", " ").replace(/-/g, "/").substr(0, 10);
}

function getCompNameStr(vars, flow) {
  if (flow === 1) {
    return ""
  } else if ([2, 3, 4].includes(flow)) {
    return `<div style="display: inline-block;position: absolute;width: 100px;left: 150px;top:50px;border: 1px solid tomato;color:tomato;transform: skewY(-20deg);">
          ${vars}
        </div>`
  }
}

function getDesignStr(flow, vars) {
  if ([2].includes(flow)) {
    return `<tr style="border: 1px solid #808080;height: 200px;">
                <td width="25%" style="border-right: 1px solid #808080;">南康工业(家具)<br/>设计中心意见</td>
                <td width="25%" colspan="3" style="position: relative;">
                  <div style="position:absolute;right: 100px;">( 盖章 )</div>
                  <div>${vars.result1}</div>
                </td>
              </tr>`
  } else {
    return ""
  }
}

function getFurStr(flow, vars) {
  if (flow === 4) {
    return `<tr style="border: 1px solid #808080;height: 200px;">
                <td width="25%" style="border-right: 1px solid #808080;">区家具产业促进局<br/>意见</td>
                <td width="25%" colspan="3" style="position: relative;">
                <div style="position:absolute;right: 100px;">( 盖章 )</div>
                 <div>${vars.result2}</div>
                </td>
              </tr>`
  } else {
    return ""
  }
}

export const gennerateHTMLtemplate = (vars, flow) => {
  vars.result1 = vars.result1 || '';
  vars.result2 = vars.result2 || '';
  vars.is_in_spec = vars.is_in_spec === 0 ? "否" : vars.is_in_spec === 1 ? "是" : "";
  return `
          <!doctype html>
          <html lang="zh">
          <head>
            <meta charSet="utf-8"/>
          </head>
          <body>
            <table style="border-collapse: collapse;text-align: center;">
              <tr style="height: 60px;">
                <th colspan="4" style="
                font-size: 30px;
                font-weight: 600;
                color: #4d4d4d;">南康工业(家具)设计奖补申请表</th>
              </tr>
              <tr style="height: 200px;">
                <td width="25%" colspan="2" style="text-align: left;position: relative;">
                  <div style="display: inline-block;">申请单位( 盖章 ):</div>
                </td>
                <td width="25%" colspan="2" style="text-align: right;">
                  <span>申请时间:</span>
                  <span style="display: inline-block;min-width: 150px;text-align: left;">${transformDate(vars.createdAt)}</span>
                </td>
              </tr>
              <tr style="border: 1px solid #808080;height: 60px;">
                <td width="25%" style="border-right: 1px solid #808080;">企业名称<br/>(以工商注册为准)</td>
                <td width="25%" style="border-right: 1px solid #808080;">${vars.compName}</td>
                <td width="25%" style="border-right: 1px solid #808080;">企业地址</td>
                <td width="25%">${vars.compAddr}</td>
              </tr>
              <tr style="border: 1px solid #808080;height: 60px;">
                <td width="25%" style="border-right: 1px solid #808080;">企业服务范围</td>
                <td width="25%" style="border-right: 1px solid #808080;">${vars.compRange}</td>
                <td width="25%" style="border-right: 1px solid #808080;">是否为规上企业</td>
                <td width="25%">${vars.is_in_spec}</td>
              </tr>
              <tr style="border: 1px solid #808080;height: 60px;">
                <td width="25%" style="border-right: 1px solid #808080;">法定代表人</td>
                <td width="25%" style="border-right: 1px solid #808080;">${vars.legalName}</td>
                <td width="25%" style="border-right: 1px solid #808080;">法定代表人<br/>联系电话</td>
                <td width="25%">${vars.legalTel}</td>
              </tr>
              <tr style="border: 1px solid #808080;height: 60px;">
                <td width="25%" style="border-right: 1px solid #808080;">企业本年度实际研<br/>发费用(万元)</td>
                <td width="25%" style="border-right: 1px solid #808080;">${vars.fee}</td>
                <td width="25%" style="border-right: 1px solid #808080;">申请奖补金额<br/>(万元)</td>
                <td width="25%">${vars.applyAmount}</td>
              </tr>
              <tr style="border: 1px solid #808080;height:  150px;">
                <td width="25%" style="border-right: 1px solid #808080;">企业简介</td>
                <td width="25%" colspan="3">
                  ${vars.compIntro}
                </td>
              </tr>
              ${getDesignStr(flow, vars)}
              ${getFurStr(flow, vars)}
              <tr style="border: 1px solid #808080;height: 200px;text-align: left;">
                <td width="25%" colspan="4" style="border-right: 1px solid #808080;">备注:本表格用于首次申报南康工业（家具）设计奖补，同时报送公司营业执照复印件、法定代表人身份证复印件；与设计机构签订的设计合同；设计机构提供的全套设计图纸，包括效果图和三视图；提供由南康工业（家具）设计中心指定的所设计产品中的代表产品实物。</td>
              </tr>
            </table>
            <div style="text-align:center;margin-top:15px;">${flow === 2 ? `[${vars.id}]工业设计中心联` : flow === 4 ? `[${vars.id}]家具产业促进局联` : ''}</div>
          </body>
          </html>`;
}

// 图章尺寸为 200 * 200
export const createSeal = (id, company, name) => {

  var canvas = document.getElementById(id);
  var context = canvas.getContext('2d');

  // 绘制印章边框   
  var width = canvas.width / 2;
  var height = canvas.height / 2;
  context.lineWidth = 2;
  context.strokeStyle = "#f00";
  context.beginPath();
  context.arc(width, height, 76, 0, Math.PI * 2);
  context.stroke();

  //画五角星
  create5star(context, width, height, 30, "#f00", 0);

  // 绘制印章名称   
  context.font = '12px Helvetica';
  context.textBaseline = 'middle';//设置文本的垂直对齐方式
  context.textAlign = 'center'; //设置文本的水平对对齐方式
  context.lineWidth = 1;
  context.fillStyle = '#f00';
  context.fillText(name, width, height + 55);

  // 绘制印章单位   
  context.translate(width, height);// 平移到此位置,
  context.font = '20px Helvetica'
  var count = company.length;// 字数   
  var angle = 4 * Math.PI / (3 * (count - 1));// 字间角度   
  var chars = company.split("");
  var c;
  for (var i = 0; i < count; i++) {
    c = chars[i];// 需要绘制的字符   
    if (i == 0)
      context.rotate(5 * Math.PI / 6);
    else
      context.rotate(angle);
    context.save();
    context.translate(90, 0);// 平移到此位置,此时字和x轴垂直   
    context.rotate(Math.PI / 2);// 旋转90度,让字平行于x轴   
    context.fillText(c, 0, 30);// 此点为字的中心点   
    context.restore();
  }

  //绘制五角星  
  /** 
   * 创建一个五角星形状. 该五角星的中心坐标为(sx,sy),中心到顶点的距离为radius,rotate=0时一个顶点在对称轴上 
   * rotate:绕对称轴旋转rotate弧度 
   */
  function create5star(context, sx, sy, radius, color, rotato) {
    context.save();
    context.fillStyle = color;
    context.translate(sx, sy);//移动坐标原点  
    context.rotate(Math.PI + rotato);//旋转  
    context.beginPath();//创建路径  
    var x = Math.sin(0);
    var y = Math.cos(0);
    var dig = Math.PI / 5 * 4;
    for (var i = 0; i < 5; i++) {//画五角星的五条边  
      x = Math.sin(i * dig);
      y = Math.cos(i * dig);
      context.lineTo(x * radius, y * radius);
    }
    context.closePath();
    context.stroke();
    context.fill();
    context.restore();
  }
}
